<div class="modal-dialog zone-modal">
    <div class="modal-content">
      <div class="zone-title">
        <app-text class="text-secondary700">{{locale?.zones.stemwerkzZone}}</app-text>
        <app-button class="close" data-dismiss="modal" aria-label="Close">
          <app-text aria-hidden="true">&times;</app-text>
        </app-button>
      </div>
      <div class="modal-body">
        <div class="zone-info">
          <div *ngIf="permittedApps?.length == 0 || permittedApps.indexOf('steamvalley') != -1" class="cursor">
              <a class="d-flex pt-3 pb-3 pl-4 pr-4" [href]="valleyUrl" target="_blank">
                  <div class="m-auto item"> <app-image [src]="'assets/image/Zone-STEAMVALLEY.png'"></app-image></div>
              </a>
          </div>
          <div *ngIf="permittedApps?.length == 0 || permittedApps.indexOf('channel') != -1" class="cursor">
              <a class="d-flex pt-3 pb-3 pl-4 pr-4" [href]="'/'" target="_blank">
                <div class="m-auto item"> <app-image [src]="'assets/image/Zone-CHANNEL.png'"></app-image></div>
              </a>
          </div>
          <div *ngIf="permittedApps?.length == 0 || permittedApps.indexOf('academy') != -1" class="cursor">
              <a class="d-flex pt-3 pb-3 pl-4 pr-4" [href]="academyUrl" target="_blank">
                  <div class="m-auto item"> <app-image [src]="'assets/image/Zone_ACADEMY.png'"></app-image></div>
              </a>
          </div>

      </div>
      </div>
      <div *ngIf="!isMobileApp" class="zone-footer bg-s10">
        <div class="d-flex justify-content-center align-items-center">
          <app-text> Go to</app-text>&nbsp;
          <a class="light-body-m text-c100" [href]="stemUrl" target="_blank">
            <app-text class="light-body-m text-secondary700">{{locale?.zones.stemwerkzLink}}</app-text>
          </a>
        </div>
        <a class="d-flex justify-content-center align-items-center mt-3" [href]="loginUrl" target="_blank">
          <app-text class="light-body-m text-secondary700">{{locale?.zones.parentLogin}}</app-text>
        </a>
      </div>
    </div>
</div>
