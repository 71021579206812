import {Component,Input,Output,EventEmitter,OnChanges,HostListener} from '@angular/core';
import { FormControl, AbstractControl, Validators, FormBuilder } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
@Component({
    selector: 'app-header-bar',
    templateUrl: './header-bar.component.html',
    styleUrls: ['./header-bar.component.css']
})
export class HeaderBarComponent implements OnChanges {
  @Output() searchValue = new EventEmitter<any>();
  @Output() onClickLogo = new EventEmitter<any>();
  @Output() onClickLogOut = new EventEmitter<any>();
  @Output() clickUser = new EventEmitter<any>();
  @Output() clickSearch = new EventEmitter<any>();
  @Output() kidLogin = new EventEmitter<any>();
  // @Output() onClickChannel = new EventEmitter<any>();
  @Input() profileObj;
  @Input() userObj;
  @Input() data;
  @Input() valleyUrl;
  @Input() loginUrl;
  @Input() parentLoginUrl;
  @Input() academyUrl;
  @Input() stemUrl;
  @Input() dashboardUrl;
  @Input() questsUrl;
  @Input() control: AbstractControl | FormControl;
  @Input() isMobileApp;
  @Input() permittedApps;
  @Input() locale;
  @Input() showNavBarMenu;
  @Input() isHomePage;
  private searchData = null;
  public form = this.fb.group({
    value: this.fb.control('', [Validators.required]),
  });
  public text = '';
  public isFixedNav:boolean = false;
  public scrollPosition = 0;
  public isAcademyDisabled = environment.disabledAcademy;
  public logoConfig = environment.logo;

  constructor( private fb: FormBuilder) {
  }

  @HostListener('window:scroll',['$event'])
  onScroll(e){
    let nav_height = document.getElementById('header_nav').offsetHeight;
    var scrollY = window.pageYOffset;
    if(scrollY > nav_height || scrollY == nav_height){
      this.isFixedNav = true;
    }else{
      this.isFixedNav = false;
    }
    this.scrollPosition = window.scrollY;
  }

  ngOnInit() {
    this.form.valueChanges.subscribe(value => {
        this.searchData = value;
        this.searchValue.emit(this.searchData.value);
    });
  }
  ngOnChanges(){
  }

  onFocusEvent(event: any){
    let results = document.getElementById("searchright")
    let logo = document.getElementById("logo")
    let hamburger = document.getElementById("hamburger")
    logo.style.display = 'none';
    hamburger.style.display = 'none';
    // this.text = "Cancel";
    this.text = this.locale.cancel;
  }
  onFocusOutEvent(event: any){
    let logo = document.getElementById("logo")
    let hamburger = document.getElementById("hamburger")
    logo.style.display = 'none';
    logo.style.display = 'block';
    hamburger.style.display = 'block';
    this.text = "";
 }
  getSearchValue($event){
    this.searchValue.emit($event);
  }

  onClickHome($event) {
      this.onClickLogo.emit($event);
  }

  clickLogout($event) {
      this.onClickLogOut.emit($event);
      this.closeHamburger();
  }

  onClickUser($event){
      this.clickUser.emit($event);
  }

  onSearchClick(){
    this.clickSearch.emit();
  }

  clickLogin($event){
    console.log("clickLogin>>" )
    this.closeHamburger();
    if(environment.showStartView == true){
      let url = environment.stemwerkzStartUrl;
      window.open(url,'_blank');
    } else {
      this.kidLogin.emit($event);
    }
    // this.kidLogin.emit($event);
    // this.closeHamburger();
  }

  closeHamburger() {
    if(document.body.clientWidth<992) document.getElementById("hamburger").click();
  }

  onMouseEnter(e){
    let userProfile = document.getElementById("user-profile-btn");
    userProfile.style.boxShadow = '0px 0px 0px 3px ' + this.profileObj.borderColor;
    console.log("onMouseEnter",e)
  }

  onMouseLeave(e){
    let userProfile = document.getElementById("user-profile-btn");
    userProfile.style.boxShadow = '';
    console.log("onMouseLeave",e)
  }

}
