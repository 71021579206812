import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {from, Observable, of} from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { AuthService } from '../../../lib/auth.service';

@Injectable({
    providedIn: 'root'
})
export class DataService {
    private gameUrl = environment.stemgameapiurl;

    constructor(private http: HttpClient,private _authService: AuthService) {}

    getPlayerData(playerId){
      let userInfo = this._authService.getUserInfo();
      let url = this.gameUrl + 'api/v2/players/' + playerId;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          apikey: userInfo.apikey,
          username: userInfo.username,
          password: userInfo.password
        })
      };
      return this.http.get(url, httpOptions).pipe(map((res:Response) => {
        let result = [];
        result.push(res);
        return result;
      }))
    }

    getValleyPopupStatus(data){
      let userInfo = this._authService.getUserInfo();
      let url = this.gameUrl + 'api/v2/users/dialog-options';
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          apikey: userInfo.apikey,
          username: userInfo.username,
          password: userInfo.password
        })
      };
      return this.http.get(url, httpOptions).pipe(map((res:Response) => {
        let result = [];
        result.push(res);
        return result;
      }))
    }

    setValleyPopupStatus(data){
      let userInfo = this._authService.getUserInfo();
      let url = this.gameUrl + 'api/v2/users/dialog-options';
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          apikey: userInfo.apikey,
          username: userInfo.username,
          password: userInfo.password
        })
      };
      return this.http.put(url, data, httpOptions).pipe(map((res:Response) => {
        let result = [];
        result.push(res);
        return result;
      }))
    }
}
