<!-- <div id="header_nav" class="d-flex justify-content-between align-items-center header-bar bg-s0">
  <div class="d-flex align-items-center">
      <div class="d-flex align-items-center h-64 logo-wrapper">
          <app-image [src]="'./assets/image/STEMWerkzLogo2.png'" [width]="'100%'" [height]="'100%'" [class]="'mr-3 user-btn cursor'" (click)="onClickHome($event)"></app-image>
      </div>
      <app-search [control]="data" [hint]="'What are you looking for?'" (searchOutput)="getSearchValue($event)" (searchClick)="onSearchClick()"></app-search>
  </div>
  <div class="d-flex">
    <a *ngIf="profileObj && profileObj.text" [routerLink]="[academyUrl]" class="d-flex justify-content-center align-items-center redirect-logo-wrap">
      <div class="academy-logo">
        <app-image [src]="'./assets/image/STEMWerkzAcademy.png'" [width]="'100%'" [height]="'100%'"></app-image>
      </div>
    </a>
    <a *ngIf="profileObj && profileObj.text" class="d-flex justify-content-center align-items-center redirect-logo-wrap" [href]="valleyUrl" target="_blank">
      <div class="valley-logo">
        <app-image [src]="'./assets/image/STEAMValley.png'" [width]="'100%'" [height]="'100%'"></app-image>
      </div>
    </a>
    <div *ngIf="profileObj && profileObj.text" class="position-relative">
        <div class="user-btn cursor" (click)="onClickUser('user')">
            <app-image [src]="profileObj.url" [width]="'40px'" [height]="'40px'" [class]="'mr-3'"></app-image>
            <div *ngIf="profileObj.isDisplay" class="profile_initial"> <app-text class="body-m text-c300">{{ profileObj.text }}</app-text></div>
        </div>
        <app-user-popup [profileObj]="profileObj" [userObj]="userObj" (onClickUserLogOut)="clickLogout($event)"></app-user-popup>
    </div>
  </div>
</div> -->
<nav id="header_nav" class="cl new-bar navbar navbar-expand-lg navbar-light bg-s0" [ngClass]="{'fixed-nav': isFixedNav}">
  <!-----------Zone Modal Start-------->
  <app-mobile-switch-zone data-backdrop="false" class="modal fade" id="mobile-switch-modal" tabindex="-1" aria-labelledby="mobileSwitchModalLabel" aria-hidden="true"
    [valleyUrl]="valleyUrl"
    [academyUrl]="academyUrl"
    [stemUrl]="stemUrl"
    [loginUrl]="parentLoginUrl"
    [isMobileApp]="isMobileApp"
    [permittedApps]="permittedApps"
    [locale]="locale">
  </app-mobile-switch-zone>
  <!-----------Zone Modal End-------->


    <div class="d-flex align-items-center">
      <div class="d-flex align-items-center h-64 logo-wrapper">
        <app-image *ngIf="logoConfig == 'english'" id="logo" [src]="'./assets/image/STEMWerkzLogo2.png'" [width]="'100%'" [height]="'100%'" [class]="'mr-3 user-btn cursor'" (click)="onClickHome($event)"></app-image>
        <app-image *ngIf="logoConfig == 'chinese'" id="logo" [src]="'./assets/image/STEMWerkzLogo2-ch.png'" [width]="'100%'" [height]="'100%'" [class]="'mr-3 user-btn cursor'" (click)="onClickHome($event)"></app-image>
      </div>
    </div>

    <app-search *ngIf="(isHomePage && scrollPosition) || !isHomePage" [id]="'Search'" [control]="data" [hint]="locale?.searchInput.searchPlaceholder" (searchOutput)="getSearchValue($event)" (searchClick)="onSearchClick()"></app-search>

    <div *ngIf="(isHomePage && scrollPosition) || !isHomePage" class="search-container" [ngStyle]="{'margin-right': showNavBarMenu ? '10px' : '0px'}">
      <form class="form" [formGroup]="form" action="/search" method="get">
        <input formControlName="value" (focusout)="onFocusOutEvent($event)" (focus)="onFocusEvent($event)" (onClick)="getSearchValue($event)" (searchClick)="onSearchClick()" class="search expandright" id="searchright" type="search" [placeholder]="locale?.searchInput.searchPlaceholder">
        <app-text *ngIf="text" class="text ml-2">{{text}}</app-text>
        <label *ngIf="!text" class="button searchbutton" for="searchright"><span class="mglass"></span></label>
      </form>
    </div>

    <input class="menu-btn" type="checkbox" id="menu-btn">
    <label *ngIf="profileObj && profileObj.text && showNavBarMenu" id="hamburger" data-toggle="collapse" data-target="#navbarSupportedContent" class="menu-icon" for="menu-btn">
      <span class="navicon"></span>
    </label>

    <label *ngIf="!profileObj.text && showNavBarMenu" id="hamburger" data-toggle="collapse" data-target="#NoAuthNavbarSupportedContent" class="menu-icon" for="menu-btn">
      <span class="navicon"></span>
    </label>

    <div *ngIf="!profileObj.text && showNavBarMenu" class="collapse navbar-collapse" id="NoAuthNavbarSupportedContent">
      <ul class="navbar-nav ml-auto">
        <li *ngIf="!isMobileApp" class="nav-item">
          <a class="d-flex justify-content-center align-items-center" [href]="stemUrl" target="_blank">
            <div class="no-auth-button-w154">
              <app-button [padding]="'0px'" [class]="'flat-button'" [width]="'100%'" [height]="'40px'">
                <app-text class="body-m">{{locale?.navButton.visitStemWerkz}}</app-text>
              </app-button>
            </div>
          </a>
        </li>
        <li *ngIf="!isMobileApp" class="nav-item">
          <a class="d-flex justify-content-center align-items-center" [href]="loginUrl" target="_blank">
            <div class="no-auth-button-w154">
              <app-button [padding]="'0px'" [class]="'flat-button'" [width]="'100%'" [height]="'40px'">
                  <app-text class="body-m">{{locale?.navButton.parentPortal}}</app-text>
                </app-button>
            </div>
          </a>
        </li>
        <li class="nav-item">
          <div class="d-flex justify-content-center align-items-center">
            <div class="no-auth-button-w170">
              <app-button [class]="'kid-btn'" [padding]="'0px'" [width]="'100%'" [height]="'40px'" (onClick)="clickLogin($event)">
                  <app-text class="text-s0 body-m">{{locale?.navButton.kidZone}}</app-text>
                </app-button>
            </div>

          </div>
        </li>
      </ul>
    </div>

    <div *ngIf="profileObj && profileObj.text && showNavBarMenu" class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">

        <li class="nav-item profile-info bg-s10">
         <div class="d-flex align-items-center">
          <div class="item w-40">
            <app-image [src]="profileObj.url" [width]="'40px'" [height]="'40px'" ></app-image>
            <div class="profile_initial"> <app-text class="body-m text-c300">{{ profileObj.text }}</app-text></div>
          </div>
          <div *ngIf="userObj.username" class="ml-3 item zone-text body-m text-c300">
            <app-text>{{ userObj.username }}</app-text>
          </div>
         </div>
        </li>

        <li class="d-flex bg-s20 dashboard-menu-wrap position-relative">
            <a class="d-flex w-100" [href]="dashboardUrl">
              <div class="icon-wrap">
                <app-image [src]="'assets/image/Zone-DASHBOARD.png'" [width]="'100%'" [height]="'100%'"></app-image>
              </div>
              <div class="mt-3">
                <div class="text-c300 menu-dashboard">
                  {{ locale?.zones.dashboardTitle }}
                </div>
                <div class="text-c100 caption-light">
                  {{ locale?.zones.dashboardText }}
                </div>
              </div>
              <div class="position-absolute d-flex align-items-center h-100 r-30">
                <app-image [src]="'./assets/image/rightArrow.svg'" [width]="'8px'" [height]="'14px'"></app-image>
              </div>
            </a>
        </li>

        <li class="menu-flex-container">

          <div *ngIf="permittedApps?.length == 0 || permittedApps.indexOf('steamvalley') != -1">
            <a class="d-flex justify-content-center align-items-center redirect-logo-wrap" [href]="valleyUrl" target="_blank">
              <div class="valley-logo">
                <app-image *ngIf="logoConfig == 'english'" [src]="'./assets/image/STEAMValley.png'" [width]="'100%'" [height]="'100%'"></app-image>
                <app-image *ngIf="logoConfig == 'chinese'" [src]="'./assets/image/Zone-STEAMVALLEY-ch.png'" [width]="'100%'" [height]="'100%'"></app-image>
              </div>
            </a>
          </div>

          <div *ngIf="permittedApps?.length == 0 || permittedApps.indexOf('channel') != -1">
            <a [routerLink]="'/'" class="d-flex justify-content-center align-items-center redirect-logo-wrap" (click)="closeHamburger()">
              <div class="channel-logo">
                <app-image  *ngIf="logoConfig == 'english'" [src]="'./assets/image/STEMWerkzLogo2.png'" [width]="'100%'" [height]="'100%'"></app-image>
                <app-image  *ngIf="logoConfig == 'chinese'" [src]="'./assets/image/Zone-CHANNEL-ch.png'" [width]="'100%'" [height]="'100%'"></app-image>
              </div>
            </a>
          </div>

          <div *ngIf="!isAcademyDisabled && (permittedApps?.length == 0 || permittedApps.indexOf('academy') != -1)">
            <a [routerLink]="[academyUrl]" class="d-flex justify-content-center align-items-center redirect-logo-wrap" (click)="closeHamburger()">
              <div class="academy-logo">
                <app-image  [src]="'./assets/image/STEMWerkzAcademy.png'" [width]="'100%'" [height]="'100%'"></app-image>
              </div>
            </a>
          </div>

          <div *ngIf="permittedApps?.length == 0 || permittedApps.indexOf('quest') != -1">
            <a class="d-flex justify-content-center align-items-center redirect-logo-wrap" [href]="questsUrl" target="_blank">
              <div class="d-flex justify-content-center align-items-center position-relative w-100 h-100">
                <div class="quest-logo">
                  <app-image  *ngIf="logoConfig == 'english'" [src]="'./assets/image/STEMQuest.png'" [width]="'100%'" [height]="'100%'"></app-image>
                  <app-image  *ngIf="logoConfig == 'chinese'" [src]="'./assets/image/Zone-STEMQuest-ch.png'" [width]="'100%'" [height]="'100%'"></app-image>
                </div>
              </div>
            </a>
          </div>
        </li>

        <li class="nav-menu-icon align-items-center justify-content-center">
          <div class="position-relative profile">
            <!-- <div class="zone-btn cursor" (click)="onClickUser('zone')">
                <app-image [src]="'./assets/image/zone.png'"></app-image>
            </div> -->
            <div class="zone-btn cursor" (click)="onClickUser('zone')">
                <app-image [src]="'./assets/image/Zone-DASHBOARD.png'" [width]="'115%'" [height]="'115%'" [class]="'zone-nav-icon'"></app-image>
            </div>
            <app-zone [stemUrl]="stemUrl" [vUrl]="valleyUrl" [academyUrl]="academyUrl" [loginUrl]="parentLoginUrl" [dashboardUrl]="dashboardUrl" [questsUrl]="questsUrl" [isMobileApp]="isMobileApp" [permittedApps]="permittedApps" [locale]="locale"></app-zone>
          </div>
          <!-- <div class="d-flex zone-logo align-items-center justify-content-center">
            <div class="w-100 ml-3 mr-3">
              <app-button [class]="'kid-btn'" [width]="'100%'" data-toggle="modal" data-target="#mobile-switch-modal" (onClick)="closeHamburger()">
                <app-image [src]="'./assets/image/zone.png'" [width]="'32px'" [height]="'32px'"></app-image>
                &nbsp;
                <app-text class="body-l text-s0">{{locale?.navButton.mobileSwitchZone}}</app-text>
              </app-button>
            </div>
          </div> -->
        </li>

        <li class="mobile-menu-list">
          <div class="light-body-m text-c100 d-flex">
            <div *ngFor="let data of locale?.zones.stemwerkzUrlLabel" class="pr-1">
              <app-text *ngIf="data!='stemwerkzLink'">{{data}}</app-text>
              <a *ngIf="data=='stemwerkzLink'" class="text-secondary700" [href]="stemUrl" target="_blank">{{locale.zones[data]}}</a>
            </div>
          </div>
          <div class="light-body-m">
            <a class="text-secondary700" [href]="loginUrl" target="_blank">
                {{locale?.zones.parentLogin}}
            </a>
          </div>
          <div class="text-r100 light-body-m" (click)="clickLogout($event)">
            <app-text class="cursor">{{ locale?.profile.logoutButton }}</app-text>
          </div>
        </li>

        <li class="nav-menu-icon align-items-center justify-content-center">
          <!-- <a class="cursor position-relative logout-text d-flex justify-content-center align-items-center redirect-logo-wrap" (click)="clickLogout($event)">
            <app-text class="text-c300 body-l">{{locale?.profile.logoutButton}}</app-text>
          </a> -->
          <div class="position-relative profile" (mouseenter)="onMouseEnter($event)" (mouseleave)="onMouseLeave($event)">
            <div id="user-profile-btn" class="user-btn cursor" (click)="onClickUser('user')">
                <app-image [src]="profileObj.url" [width]="'100%'" [height]="'40px'" [class]="'mr-3'"></app-image>
                <div *ngIf="profileObj.isDisplay" class="profile_initial"> <app-text class="body-m text-c300">{{ profileObj.text }}</app-text></div>
            </div>
            <app-user-popup [profileObj]="profileObj" [userObj]="userObj" [locale]="locale" (onClickUserLogOut)="clickLogout($event)"></app-user-popup>
          </div>
        </li>
      </ul>
    </div>

  </nav>







