import { HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../lib/auth.service";
export class DataService {
    constructor(http, _authService) {
        this.http = http;
        this._authService = _authService;
        this.gameUrl = environment.stemgameapiurl;
    }
    getPlayerData(playerId) {
        let userInfo = this._authService.getUserInfo();
        let url = this.gameUrl + 'api/v2/players/' + playerId;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                apikey: userInfo.apikey,
                username: userInfo.username,
                password: userInfo.password
            })
        };
        return this.http.get(url, httpOptions).pipe(map((res) => {
            let result = [];
            result.push(res);
            return result;
        }));
    }
    getValleyPopupStatus(data) {
        let userInfo = this._authService.getUserInfo();
        let url = this.gameUrl + 'api/v2/users/dialog-options';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                apikey: userInfo.apikey,
                username: userInfo.username,
                password: userInfo.password
            })
        };
        return this.http.get(url, httpOptions).pipe(map((res) => {
            let result = [];
            result.push(res);
            return result;
        }));
    }
    setValleyPopupStatus(data) {
        let userInfo = this._authService.getUserInfo();
        let url = this.gameUrl + 'api/v2/users/dialog-options';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                apikey: userInfo.apikey,
                username: userInfo.username,
                password: userInfo.password
            })
        };
        return this.http.put(url, data, httpOptions).pipe(map((res) => {
            let result = [];
            result.push(res);
            return result;
        }));
    }
}
DataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataService_Factory() { return new DataService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthService)); }, token: DataService, providedIn: "root" });
