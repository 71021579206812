import { UserModel } from '../../../models/user.model';
import {ItemMapper} from '../../../lib/item-mapper';

export class UserMapper extends ItemMapper<UserModel> {
    protected mapFromItem(item): UserModel {
        const userModel = new UserModel();
        userModel.username = item.username;
        userModel.userid = item._id;
        userModel.profileImg = item? item.generatedProfileImage.profileImage : null;
        userModel.text = item? item.generatedProfileImage.initials : null;
        userModel.profileImgBorder = item ? this.getBorderColor(item.generatedProfileImage.profileImage) : null;
        userModel.password = item.token;
        userModel.apikey = item.apikey;
        userModel.locale = item.metadata.locale != undefined ? item.metadata.locale : 'en-US';
        userModel.currentLocale = item.metadata.currentLocale != undefined ? item.metadata.currentLocale : null;
        userModel.secondaryLocale = item.metadata.secondaryLocale != undefined ? item.metadata.secondaryLocale : null;

        delete userModel.idUser;
        delete userModel.orgname;
        delete userModel.permittedApps;
        return userModel;
    }

    getBorderColor(profileImgUrl){
      let color;
      let bgColor = this.getBackgroundColor(profileImgUrl);
      switch(bgColor) {
        case 'green':
          color = '#52CC85';
          break;
        case 'grey':
          color = "#7A8A99";
          break;
        case 'red':
          color = "#FF9E99";
          break;
        default:
          color = "#FFBF00";
      }
      return color;
    }

    getBackgroundColor(imageUrl){
      var filename = imageUrl.match(/.*\/(.*)$/)[1];
      let backgroundColor = filename.substring(0,filename.indexOf('_'));
      return backgroundColor;
    }
}
