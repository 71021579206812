import {Component,Input,Output,EventEmitter,HostListener} from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-zone',
    templateUrl: './zone.component.html',
    styleUrls: ['./zone.component.css']
})
export class ZoneComponent {
    @Input() vUrl;
    @Input() academyUrl;
    @Input() loginUrl;
    @Input() stemUrl;
    @Input() dashboardUrl;
    @Input() questsUrl;
    @Input() isMobileApp;
    @Input() permittedApps;
    @Input() locale;

    public count = 0;
    public stemwerkzUrlLabel;
    public urlDiv;
    public isAcademyDisabled = environment.disabledAcademy;
    public logoConfig = environment.logo;
    // @HostListener('document:click',['$event'])
    // onClick(e){
    //     let zoneDiv = document.getElementById("zone-toogle");
    //     console.log("this.cnt",zoneDiv.style.display)
    //     if(this.count == 0 && zoneDiv.style.display == "block"){
    //         zoneDiv.style.display = "block";
    //         this.count++;
    //     }else if(this.count == 1){
    //         zoneDiv.style.display = "none";
    //         this.count--;
    //     }
    // }

    ngOnInit(){
      // Go to <a class="text-secondary700" [href]="stemUrl" target="_blank">www.stemwerkz.org</a>
      // this.locale.zones.stemwerkzUrlLabel.replace('{{stemwerkzLink}}',this.stemwerkzUrlLabel);

      console.log('view div')
      console.log(this.urlDiv )
      console.log();
      // this.stemwerkzUrlLabel = 'Go to <a class="text-secondary700" [href]="stemUrl" target="_blank">www.stemwerkz.org</a>'
      // setTimeout(() => {
      //   var urlString;
      //   urlString='Go to <a class="text-secondary700" [href]="stemUrl" target="_blank">';
      //   urlString+= this.locale.zones.stemwerkzLink;
      //   urlString += '</a>';
      //   document.querySelector('#stemwerkz_url_label').innerHTML=urlString;
      // }, 1000);
      // this.parseString();
    }

    public testString;

    parseString(){
      var originalString = this.locale.zones.stemwerkzUrlLabel;
      originalString = this.replaceString(originalString,'stemwerkzLink',this.locale.zones.stemwerkzLink);
      originalString = this.replaceString(originalString,'{{','<a class="text-secondary700" [href]="stemUrl" target="_blank">');
      originalString = this.replaceString(originalString,'}}','</a>');
      this.testString = originalString;
    }

    replaceString(originalStr,original,replacement){
      var data = originalStr.replace(original,replacement);
      console.log(data);
      return data;
    }
}
