export const environment = {
  production: true,
  envName: 'production',
  apiurl: 'https://channel-api.stemwerkz.org/',
  apikey: 'bc77612ecd1a2e7f6ace383b4cee7848',
  stemgameapiurl: 'https://sv-api.stemwerkz.org/',
  secretkey: 'bc776',
  valleyUrl: 'https://sv.stemwerkz.org/',
  dashboardUrl: 'https://student.stemwerkz.org/',
  questsUrl: 'https://quest.stemwerkz.org',
  liveClassApiurl: 'https://live-api.stemwerkz.org/',
  nodmaUrl: 'https://nodma.pagewerkz.com/',
  membershipUrl: 'https://account.stemwerkz.org/',
  stemUrl: 'https://www.stemwerkz.org',
  valleyPopupVideoUrl: 'https://du4diohu7suph.cloudfront.net/stemvalley-demo/steamValleyTeaser1.mp4',
  openAppJSUrl: 'https://pagewerkz-authoring.s3.amazonaws.com/stemwerkz/open-in-app-js/production/openInApp.js',
  stemwerkzStartUrl: 'https://start.stemwerkz.org/',
  disabledAcademy: false,
  // logo: 'chinese',
  logo: 'english',
  showStartView: false, // true or false
  pauseAcademy: true
};
