import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-mobile-switch-zone',
    templateUrl: './mobile-switch-zone.component.html',
    styleUrls: ['./mobile-switch-zone.component.css']
})
export class MobileSwitchZoneComponent {
  @Input() valleyUrl;
  @Input() academyUrl;
  @Input() stemUrl;
  @Input() loginUrl;
  @Input() isMobileApp;
  @Input() permittedApps;
  @Input() locale;

}
